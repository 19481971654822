import { useCallback, useEffect, useRef, useState } from "react";
import "./App.scss";

import Draggable from "./components/draggable/draggable";
import Navigation from "./components/navigation/navigation";
import Panel from "./components/panel/panel";
import AlertComponent from "./components/welcome/welcome";

import PanelA001 from "./panels/a_001/panel";
import PanelA003 from "./panels/a_003/panel";
import PanelB003 from "./panels/b_003/panel";

function setupGrid(draggableRef, columns, rows) {
  if (draggableRef.current) {
    const container = draggableRef.current.getScrollableContainer();
    if (container) {
      // Each panel is 300px x 300px

      let targetPanelColumn = 1.5;
      let targetPanelRow = 0.5;

      container.scrollTo({
        top: 300 * targetPanelRow,
        left: 300 * targetPanelColumn,
      });
    }
  }
}

function App() {
  const [showAlert, setShowAlert] = useState(true);

  const rows = 12;
  const columns = 11;

  // useEffect(() => {
  //   const timer = setTimeout(() => setShowAlert(false), 20000);
  //   return () => clearTimeout(timer);
  // }, []);

  const draggableRef = useRef();

  useEffect(() => {
    setupGrid(draggableRef, columns, rows);
  }, []);

  const scrollGrid = useCallback(
    (direction) => {
      if (draggableRef.current) {
        const container = draggableRef.current.getScrollableContainer();
        const scrollAmount = 300; // The amount to scroll
        let newScrollX = container.scrollLeft;
        let newScrollY = container.scrollTop;

        switch (direction) {
          case "up":
            newScrollY -= scrollAmount;
            break;
          case "down":
            newScrollY += scrollAmount;
            break;
          case "left":
            newScrollX -= scrollAmount;
            break;
          case "right":
            newScrollX += scrollAmount;
            break;
          default:
            break;
        }

        container.scrollTo({
          top: newScrollY,
          left: newScrollX,
          behavior: "smooth",
        });
      }
    },
    [draggableRef]
  );

  const generatePanel = useCallback(
    (row, column) => {
      const panelKey = `${row}_${column}`;
      switch (panelKey) {
        case "a_001":
          return <PanelA001 key={panelKey} />;
        case "a_003":
          return <PanelA003 key={panelKey} />;
        case "b_003":
          return <PanelB003 key={panelKey} />;
        default:
          return (
            <Panel
              image_path={`/assets/slices/${panelKey}.jpg`}
              key={panelKey}
            />
          );
      }
    },
    [columns]
  );

  const handleKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case "w": // w
        case "ArrowUp": // up arrow
        case "k": // vim
          scrollGrid("up");
          break;
        case "a": // a
        case "ArrowLeft": // left arrow
        case "h": // vim
          scrollGrid("left");
          break;
        case "s": // s
        case "ArrowDown": // down arrow
        case "j": // vim
          scrollGrid("down");
          break;
        case "d": // d
        case "ArrowRight": // right arrow
        case "l": // vim
          scrollGrid("right");
          break;
        case "Escape":
          setShowAlert(false);
          break;
        case "Enter":
          setShowAlert(false);
          break;
        default:
          break;
      }
    },
    [scrollGrid]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <>
      {showAlert && <AlertComponent onClose={() => setShowAlert(false)} />}
      <Navigation onScroll={scrollGrid} />
      <Draggable ref={draggableRef}>
        <div id="grid">
          {Array.from({ length: rows * columns }).map((_, index) => {
            const row = Math.floor(index / columns);
            const column = index % columns;

            const rowAlpha = String.fromCharCode(97 + row);
            const columnPadded = (column + 1).toString().padStart(3, "0");

            return generatePanel(rowAlpha, columnPadded);
          })}
        </div>
      </Draggable>
    </>
  );
}

export default App;
