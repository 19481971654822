import React from "react";

import "./panel.scss";

import Marquee from "../../components/marquee/marquee";
import { Panel } from "../../components/panel/panel";

const PanelA003 = () => {
  return (
    <Panel image_path="/assets/slices/a_003.jpg">
      <Marquee
        top="232"
        left="188"
        width="54"
        height="10"
        spanWidth="300"
      ></Marquee>
    </Panel>
  );
};

export default PanelA003;
