const Sketch = (p) => {
  let lineConfigs = [
    {
      y: 0,
      lineWidth: 0,
      frameCounter: 0,
      delayFrames: p.random(20, 60),
      color: "rgba(255, 255, 255, 0.5)",
    },
    {
      y: 10,
      lineWidth: 0,
      frameCounter: 0,
      delayFrames: p.random(30, 60),
      color: "rgba(255, 255, 255, 0.5)",
    },
    {
      y: 20,
      lineWidth: 0,
      frameCounter: 0,
      delayFrames: p.random(10, 60),
      color: "rgba(255, 255, 255, 0.5)",
    },
    {
      y: 73,
      lineWidth: 0,
      frameCounter: 0,
      delayFrames: p.random(0, 60),
      color: "rgba(255, 255, 255, 0.75)",
    },
    {
      y: 120,
      lineWidth: 0,
      frameCounter: 0,
      delayFrames: p.random(0, 60),
      color: "rgba(255, 255, 255, 0.75)",
    },
  ];
  let gfx; // Graphics layer

  p.setup = () => {
    let canvasHeight = Math.max(...lineConfigs.map((config) => config.y)) + 2;
    p.createCanvas(70, canvasHeight);
    gfx = p.createGraphics(70, canvasHeight); // Create a graphics layer
  };

  p.draw = () => {
    gfx.clear(); // Clear the graphics layer instead of using background()

    lineConfigs.forEach((config) => {
      if (config.frameCounter < config.delayFrames) {
        config.frameCounter++;
      } else {
        gfx.stroke(config.color);
        gfx.strokeWeight(1);

        // Draw each line on the graphics layer
        gfx.line(0, config.y, config.lineWidth, config.y);

        config.lineWidth++;
        if (config.lineWidth > 70) {
          config.lineWidth = 0;
          config.frameCounter = 0;
          config.delayFrames = p.random(30, 60);
        }
      }
    });

    p.clear(); // Clear the main canvas
    p.image(gfx, 0, 0); // Draw the graphics layer onto the main canvas
  };
};

export { Sketch };
