import React from "react";
import "./marquee.scss"; // Import the CSS for styling

const Marquee = ({
  text,
  top,
  left,
  width,
  height,
  textColor,
  spanWidth,
  direction,
}) => {
  // Set default values for props
  direction = direction || "right";
  textColor = textColor || "#000000";
  top = top || 0;
  left = left || 0;
  width = width || 100;
  spanWidth = spanWidth || 200;
  height = height || 10;
  text =
    text ||
    "This is a test, this is only a test. If this were a real emergency, this would be a real message.";

  // Set the direction class
  let directionClass = "";

  switch (direction) {
    case "right":
      directionClass = "pan-right";
      break;
    case "left":
      directionClass = "pan-left";
      break;
    default:
      directionClass = "pan-right";
      break;
  }

  return (
    <div
      className="marquee"
      style={{
        top: `${top}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <span
        style={{
          display: "inline-block",
          width: `${spanWidth}px`,
          color: textColor,
        }}
        className={directionClass}
      >
        {text}
      </span>
    </div>
  );
};

export default Marquee;
