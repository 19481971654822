const Sketch = (p) => {
  const MAX_PARTICLES = 10;
  const STRIATED_ALPHA = 50;
  const PARTICLE_INTERVAL = 100;

  let particles = [];
  let buffer;
  let nextParticleTime = 0;

  p.setup = () => {
    p.createCanvas(20, 100);
    buffer = p.createGraphics(20, 100);
    p.frameRate(10);
  };

  p.draw = () => {
    buffer.clear();
    buffer.fill("#32474A");

    manageParticles();

    p.image(buffer, 0, 0);
  };

  function manageParticles() {
    if (shouldAddParticle()) {
      particles.push(new Particle());
      nextParticleTime = p.millis() + PARTICLE_INTERVAL;
    }

    particles = particles.filter((particle) => {
      buffer.drawingContext.save();
      applyClippingMask(buffer);
      const result = particle.updateAndShow(buffer);
      buffer.drawingContext.restore();
      return result;
    });
  }

  function shouldAddParticle() {
    return p.millis() > nextParticleTime && particles.length < MAX_PARTICLES;
  }

  function applyClippingMask(g) {
    g.drawingContext.beginPath();
    g.drawingContext.moveTo(0, 0);
    g.drawingContext.lineTo(10, 10);
    g.drawingContext.lineTo(10, 115);
    g.drawingContext.lineTo(0, 115);
    g.drawingContext.closePath();
    g.drawingContext.clip();
  }

  function getParticleColor(lifespan) {
    const startColor = p.color(255, 255, 255); // White color
    const endColor = p.color("#6C888C"); // Target color
    const lerpAmt = (205 - lifespan) / 205; // Normalized lifespan

    return p.lerpColor(startColor, endColor, lerpAmt);
  }

  class Particle {
    constructor() {
      this.x = p.random(buffer.width);
      this.y = 0;
      this.speed = p.random(1, 3);
      this.lifespan = 205;
      this.isStriated = isStriatedColumn(this.x);
    }

    updateAndShow(g) {
      this.update();
      this.show(g);
      return this.isAlive();
    }

    update() {
      this.y += this.speed;
      if (!this.isStriated) this.lifespan -= 5;
    }

    show(g) {
      const alpha = this.isStriated
        ? STRIATED_ALPHA
        : Math.max(0, Math.min(this.lifespan, 255));
      const particleColor = getParticleColor(this.lifespan);
      particleColor.setAlpha(alpha);

      g.stroke(particleColor);
      g.strokeWeight(2);
      g.line(this.x, this.y, this.x, this.y + 10);
    }

    isAlive() {
      return this.y < buffer.height && this.lifespan > 0;
    }
  }

  function isStriatedColumn(x) {
    return x % 9 === 0;
  }
};

export { Sketch };
