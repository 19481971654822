const Sketch = (p) => {
  // Configuration variables
  const particleSize = 1; // Size of each particle
  const maxVerticalSpeed = 0.2; // Maximum vertical speed of particles
  const minVerticalSpeed = 0.05; // Minimum vertical speed of particles
  const horizontalVariation = 0.02; // Max horizontal movement variation
  const fadeRate = 0.2; // Rate at which particles fade
  const minOpacity = 40; // Minimum starting opacity
  const maxOpacity = 90; // Maximum starting opacity
  const particleGenerationInterval = 8; // Interval for generating new particles
  const particlesPerGeneration = 4; // Number of particles generated each interval
  const canvasWidth = 80; // Width of the canvas
  const canvasHeight = canvasWidth / 4; // Height of the canvas (4:1 ratio)

  let particles = [];

  class Particle {
    constructor() {
      this.pos = p.createVector(p.random(p.width), p.height + 20);
      this.vel = p.createVector(
        0,
        -p.random(minVerticalSpeed, maxVerticalSpeed)
      );
      this.alpha = p.random(minOpacity, maxOpacity);
    }

    update() {
      this.vel.add(
        p.createVector(p.random(-horizontalVariation, horizontalVariation), 0)
      );
      this.pos.add(this.vel);
      this.alpha -= fadeRate;
    }

    show() {
      p.noStroke();
      p.fill(255, this.alpha);
      p.ellipse(this.pos.x, this.pos.y, particleSize);
    }

    finished() {
      return this.alpha < 0;
    }
  }

  p.setup = () => {
    p.createCanvas(canvasWidth, canvasHeight);
    p.background(0, 0);
  };

  p.draw = () => {
    p.clear(); // For transparent background

    // Creating an oval clipping path
    p.drawingContext.beginPath();
    p.drawingContext.ellipse(
      p.width / 2,
      p.height / 2,
      p.width / 2,
      p.height / 2,
      0,
      0,
      2 * Math.PI
    );
    p.drawingContext.clip();

    // Adding new particles at intervals
    if (p.frameCount % particleGenerationInterval === 0) {
      for (let i = 0; i < particlesPerGeneration; i++) {
        particles.push(new Particle());
      }
    }

    for (let i = particles.length - 1; i >= 0; i--) {
      particles[i].update();
      particles[i].show();
      if (particles[i].finished()) {
        particles.splice(i, 1);
      }
    }
  };
};

export { Sketch };
