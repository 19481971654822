import React, { useCallback, useReducer, useEffect, useRef } from "react";
import "./draggable.scss";

const dragReducer = (state, action) => {
  switch (action.type) {
    case "START":
      return { ...state, isDragging: true, ...action.payload };
    case "MOVE":
      return { ...state, ...action.payload };
    case "STOP":
      return { ...state, isDragging: false };
    default:
      return state;
  }
};

const Draggable = React.forwardRef(({ children }, ref) => {
  const [dragState, dispatch] = useReducer(dragReducer, {
    isDragging: false,
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const containerRef = useRef(null);

  React.useImperativeHandle(ref, () => ({
    getScrollableContainer: () => containerRef.current,
  }));

  const startDrag = useCallback((pageX, pageY) => {
    dispatch({
      type: "START",
      payload: {
        startX: pageX,
        startY: pageY,
        scrollLeft: containerRef.current.scrollLeft,
        scrollTop: containerRef.current.scrollTop,
      },
    });
    containerRef.current.classList.add("grabbing");
  }, []);

  const onDrag = useCallback(
    (pageX, pageY) => {
      if (!dragState.isDragging) return;
      const dx = pageX - dragState.startX;
      const dy = pageY - dragState.startY;
      containerRef.current.scrollLeft = dragState.scrollLeft - dx;
      containerRef.current.scrollTop = dragState.scrollTop - dy;
    },
    [dragState]
  );

  const stopDrag = useCallback(() => {
    dispatch({ type: "STOP" });
    containerRef.current.classList.remove("grabbing");
  }, []);

  useEffect(() => {
    const mouseMoveHandler = (e) => onDrag(e.pageX, e.pageY);
    const touchMoveHandler = (e) =>
      onDrag(e.touches[0].pageX, e.touches[0].pageY);

    if (dragState.isDragging) {
      window.addEventListener("mousemove", mouseMoveHandler);
      window.addEventListener("mouseup", stopDrag);
      window.addEventListener("touchmove", touchMoveHandler);
      window.addEventListener("touchend", stopDrag);
    }

    return () => {
      window.removeEventListener("mousemove", mouseMoveHandler);
      window.removeEventListener("mouseup", stopDrag);
      window.removeEventListener("touchmove", touchMoveHandler);
      window.removeEventListener("touchend", stopDrag);
    };
  }, [dragState.isDragging, onDrag, stopDrag]);

  return (
    <div
      ref={containerRef}
      onMouseDown={(e) => startDrag(e.pageX, e.pageY)}
      onTouchStart={(e) => startDrag(e.touches[0].pageX, e.touches[0].pageY)}
      className="draggable-container"
    >
      {children}
    </div>
  );
});

export default Draggable;
