import React, { useEffect, useState } from "react";
import "./welcome.scss";

const AlertComponent = ({ onClose }) => {
  const [parallelograms, setParallelograms] = useState([]);
  const colors = ["#eee", "#ddd"];
  const speed = 1.5;
  const topOffset = 15;

  useEffect(() => {
    const createParallelogramPoints = (x, color) => {
      return [
        { x: x, y: 0 },
        { x: x + 15, y: 0 },
        { x: x + 15 - topOffset, y: 16 },
        { x: x - topOffset, y: 16 },
      ]
        .map((p) => `${p.x},${p.y}`)
        .join(" ");
    };

    const initialParallelograms = [...Array(10)].map((_, i) => {
      return {
        id: i,
        points: createParallelogramPoints(i * 15, colors[i % 2]),
        color: colors[i % 2],
      };
    });

    setParallelograms(initialParallelograms);

    const interval = setInterval(() => {
      setParallelograms((prevParallelograms) =>
        prevParallelograms.map((bar) => {
          let pointsArray = bar.points
            .split(" ")
            .map((p) => p.split(",").map(Number));
          pointsArray = pointsArray.map(([x, y]) => [x - speed, y]);

          const maxX = Math.max(...pointsArray.map(([x, _]) => x));
          const svgWidth = 100; // SVG width

          if (maxX < 0) {
            pointsArray = pointsArray.map(([x, y]) => [x + svgWidth + 22, y]);
          }

          return {
            ...bar,
            points: pointsArray.map((p) => p.join(",")).join(" "),
          };
        })
      );
    }, 60 / speed); // Adjust interval for smoother animation

    return () => clearInterval(interval);
  }, []);

  // Check if the device is mobile or desktop, and display the appropriate instructions

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  let instructions = "";

  if (isMobile) {
    instructions = "Swipe to navigate";
  } else {
    instructions = "Click & drag or use keyboard to navigate";
  }

  return (
    <div id="scrim">
      <div className="alert-container">
        <div className="alert-message">
          <div id="branding">
            <svg
              width="40"
              height="15"
              viewBox="0 0 40 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.2846 0.5L8.28463 14.5H3.61689L17.6169 0.5H22.2846ZM28.8502 0.5L14.8502 14.5H10.1825L24.1825 0.5H28.8502ZM35.7528 11.1994L35.7459 11.2063L39.0396 14.5H23.0629L26.3566 11.2063L26.3497 11.1994H35.7528ZM35.4158 0.5L21.4158 14.5H16.7481L30.7481 0.5H35.4158ZM15.9768 0.5L12.6831 3.79371L12.6899 3.80059H3.28684L3.29371 3.79371L0 0.5H15.9768Z"
                fill="black"
                fillOpacity="0.87"
              />
            </svg>
            <span className="logotype">Hollowdepth</span>
          </div>
          <p>{instructions}</p>
          <svg
            width="19"
            height="7"
            viewBox="0 0 19 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.98923 0.781352L0.753205 6.17674L2.40495 6.49674C2.67691 6.52004 2.94457 6.41759 3.13145 6.21865L8.36748 0.823261L6.71573 0.503263C6.44378 0.479957 6.17612 0.582413 5.98923 0.781352ZM9.40995 0.781352L4.17392 6.17674L5.82567 6.49674C6.09763 6.52004 6.36529 6.41759 6.55217 6.21865L11.7882 0.823261L10.1364 0.503263C9.86449 0.479957 9.59683 0.582413 9.40995 0.781352ZM12.8307 0.781352L7.59464 6.17674L9.24639 6.49674C9.51834 6.52004 9.786 6.41759 9.97289 6.21865L15.2089 0.823261L13.5572 0.503263C13.2852 0.479957 13.0176 0.582413 12.8307 0.781352ZM16.9779 0.503263C16.7059 0.479957 16.4383 0.582413 16.2514 0.781352L11.0154 6.17674L12.6671 6.49674C12.9391 6.52004 13.2067 6.41759 13.3936 6.21865L18.6296 0.823261L16.9779 0.503263Z"
              fill="black"
              fillOpacity="0.18"
            />
          </svg>
          <div id="progressBarContainer">
            <svg viewBox="0 0 100 5">
              {parallelograms.map((bar) => (
                <polygon key={bar.id} points={bar.points} fill={bar.color} />
              ))}
            </svg>
          </div>
          <button onClick={onClose}>
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.14844 10.3272C3.9375 10.4209 3.70312 10.3741 3.53906 10.2334L0.164062 7.04593C0.046875 6.92874 0 6.78811 0 6.62405C0 6.48343 0.046875 6.3428 0.164062 6.22561L3.53906 3.03811C3.70312 2.87405 3.9375 2.85061 4.14844 2.92093C4.35938 3.01468 4.5 3.22561 4.5 3.43655V5.49905H9C9.39844 5.49905 9.75 5.17093 9.75 4.74905V1.37405C9.75 0.975613 10.0781 0.62405 10.5 0.62405H11.25C11.6484 0.62405 12 0.975613 12 1.37405V4.74905C12 6.41311 10.6406 7.74905 9 7.74905H4.5V9.81155C4.5 10.0459 4.35938 10.2569 4.14844 10.3272Z"
                fill="white"
              />
            </svg>
            Enter
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertComponent;
