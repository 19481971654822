import "./navigation.scss";

const Navigation = ({ onScroll }) => {
  return (
    <>
      <nav>
        <div id="logo">
          <img src="/assets/hollowdepth_mark.svg" alt="hollowdepth mark" />
        </div>
        <div id="controls">
          <div id="d-pad">
            <div
              id="d-up"
              className="direction"
              onClick={() => onScroll("up")}
            ></div>
            <div
              id="d-down"
              className="direction"
              onClick={() => onScroll("down")}
            ></div>
            <div
              id="d-left"
              className="direction"
              onClick={() => onScroll("left")}
            ></div>
            <div
              id="d-right"
              className="direction"
              onClick={() => onScroll("right")}
            ></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
