const Sketch = (p) => {
  // Configurable Variables
  let canvasHeight = 258;
  let canvasWidth = 44;
  let cellSize = 3;
  let outerRingSize = 1;
  let gradientMinAlpha = 0;
  let alphaRandomness = 50;
  let pixelDrawProbability = 0.15;
  let plusSymbolProbability = 0.25;
  let maxBarLength = 12;
  let animate = true;
  let centerDotMaxAlpha = 50;
  let applyDotDropouts = true;
  let dotDropoutProbability = 0.2;
  let verticalBarMaxAlpha = 50;
  let verticalBarProbability = 0.05;
  let verticalBarPosition = "left"; // 'left', 'center', or 'right'
  let dotColor = [255, 255, 255];

  p.setup = () => {
    p.createCanvas(canvasWidth, canvasHeight);
    p.noStroke();
    p.background(0, 0, 0, 0.25);
    if (!animate) {
      p.noLoop();
    }
    p.frameRate(2);
  };

  p.draw = () => {
    p.clear();
    if (animate) {
      p.background(0, 0, 0, 0.5);
    }

    for (let x = 0; x < p.width; x += cellSize) {
      let barLength = 0;

      for (let y = 0; y < p.height; y += cellSize) {
        let drawPlusSymbol = p.random() < plusSymbolProbability;

        for (let i = outerRingSize; i < cellSize - outerRingSize; i++) {
          for (let j = outerRingSize; j < cellSize - outerRingSize; j++) {
            let isPlusSymbol =
              drawPlusSymbol && (i === cellSize / 2 || j === cellSize / 2);
            let isCenterDot = i === cellSize / 2 && j === cellSize / 2;
            let gradientX =
              p.abs(i - cellSize / 2 + outerRingSize) /
              (cellSize / 2 - outerRingSize);
            let gradientY =
              p.abs(j - cellSize / 2 + outerRingSize) /
              (cellSize / 2 - outerRingSize);
            let alpha = p.map(
              p.max(gradientX, gradientY),
              0,
              1,
              gradientMinAlpha,
              gradientMinAlpha
            );
            alpha += p.random(-alphaRandomness, alphaRandomness);
            alpha = p.constrain(alpha, 0, 255);

            if (isCenterDot) {
              alpha = centerDotMaxAlpha;
            }

            p.fill(dotColor[0], dotColor[1], dotColor[2], alpha);
            if (
              isCenterDot ||
              isPlusSymbol ||
              p.random() < pixelDrawProbability
            ) {
              p.rect(x + i, y + j, 1, 1);
            }
          }
        }

        if (
          barLength > 0 ||
          (barLength === 0 && p.random() < verticalBarProbability)
        ) {
          if (barLength === 0) {
            barLength = p.int(p.random(1, maxBarLength + 1));
          }

          // Calculate barX based on verticalBarPosition
          let barX;
          switch (verticalBarPosition) {
            case "left":
              barX = x + outerRingSize;
              break;
            case "center":
              barX = x + cellSize / 2;
              break;
            case "right":
              barX = x + cellSize - outerRingSize - 1;
              break;
            default:
              console.error("Invalid verticalBarPosition value");
              return;
          }

          for (
            let i = y + outerRingSize;
            i < y + cellSize - outerRingSize;
            i++
          ) {
            let barAlpha = p.random(gradientMinAlpha, verticalBarMaxAlpha);
            p.fill(255, barAlpha);
            p.rect(barX, i, 1, 1);
          }
          barLength--;
        }
      }
    }

    for (let x = 0; x < p.width; x += cellSize) {
      for (let y = 0; y < p.height; y += cellSize) {
        if (applyDotDropouts && p.random() < dotDropoutProbability) {
          let centerX = x + cellSize / 2;
          let centerY = y + cellSize / 2;
          p.fill(0);
          p.rect(centerX, centerY, 1, 1);
        }
      }
    }
  };
};

export default Sketch;
