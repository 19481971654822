import React from "react";

import "./panel.scss";

export const Panel = ({ image_path, children }) => {
  return (
    <>
      <div
        className="cell"
        style={{
          backgroundImage: `url(${image_path})`,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Panel;
