import React, { useEffect, useRef } from "react";

import "./panel.scss";

import p5 from "p5";

import { Panel } from "../../components/panel/panel";
import Sketch from "../../sketches/001";

const PanelA001 = () => {
  const sketchRef = useRef();

  useEffect(() => {
    const sketch = new p5(Sketch, sketchRef.current);
    return () => {
      sketch.remove();
    };
  }, []);

  return (
    <>
      <Panel image_path="/assets/slices/a_001.jpg">
        <div
          ref={sketchRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        ></div>
      </Panel>
    </>
  );
};

export default PanelA001;
