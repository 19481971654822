import React, { useEffect, useRef } from "react";

import "./panel.scss";

import p5 from "p5";

import { Panel } from "../../components/panel/panel";
import { Sketch as Waterfall } from "../../sketches/b_003-waterfall";
import { Sketch as Mist } from "../../sketches/b_003-mist";
import { Sketch as Chart } from "../../sketches/b_003-charts";

const PanelB003 = () => {
  const waterfallRef = useRef();
  const mistRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    const waterfall = new p5(Waterfall, waterfallRef.current);
    const mist = new p5(Mist, mistRef.current);
    const chart = new p5(Chart, chartRef.current);

    return () => {
      waterfall.remove();
      mist.remove();
      chart.remove();
    };
  }, []);

  return (
    <>
      <Panel image_path="/assets/slices/b_003.jpg">
        <div
          ref={waterfallRef}
          style={{
            position: "absolute",
            top: "133px",
            left: "42px",
          }}
        ></div>
        <div
          ref={mistRef}
          style={{
            position: "absolute",
            top: "213px",
            left: "32px",
          }}
        ></div>
        <div
          ref={chartRef}
          style={{
            position: "absolute",
            top: "66px",
            left: "204px",
          }}
        ></div>
      </Panel>
    </>
  );
};

export default PanelB003;
